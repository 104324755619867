<template>
  <!-- 配置页面 -->
  <div class="box">
    <!-- <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/getCar' }">车型查询</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">筛选页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:''}">详情页</el-breadcrumb-item>
      </el-breadcrumb>
    </div>-->
    <div class="headerView">
      <!-- 轮播图 -->
      <div class="swiperView">
        <el-carousel trigger="click" height="288px">
          <el-carousel-item v-for="item in lista.pic_list" :key="item">
            <!-- <h3 class="small">{{ item }}</h3> -->
            <img style="width: 100%; height: 100%" :src="item" alt />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 信息 -->
      <div class="mainView">
        <div class="mainViewTitle">
          <!-- 一汽解放 重型 自卸汽车 182马力 4.0米 柴油 国五 -->
          {{ lista.r_model_standard_name }}
        </div>
        <div class="mainViewMian">
          <div class="mainViewMianLi">
            <div class="mainViewMianTitle">
              <div>功</div>
              <div>能</div>
              <div>类</div>
              <div>型</div>
            </div>
            <div class="mainViewMianText">{{ lista.r_index_data_vc }}</div>
          </div>
          <div class="mainViewMianLi">
            <div class="mainViewMianTitle">
              <div>车</div>
              <div>长</div>
              <div>(mm)</div>
            </div>
            <div class="mainViewMianText">{{ lista.outer_length }}</div>
          </div>
        </div>
        <div class="mainViewMian">
          <div class="mainViewMianLi">
            <div class="mainViewMianTitle">
              <div>总</div>
              <div>质</div>
              <div>量</div>
              <div>(kg)</div>
            </div>
            <div class="mainViewMianText">{{ lista.r_total_mass }}</div>
          </div>
          <div class="mainViewMianLi">
            <div class="mainViewMianTitle">
              <div>排</div>
              <div>放</div>
              <div>标</div>
              <div>准</div>
            </div>
            <div class="mainViewMianText">{{ lista.r_emission_standard }}</div>
          </div>
        </div>
        <div class="mainViewMian">
          <div class="mainViewMianLi" style="flex: 1">
            <div class="mainViewMianTitle">
              <div>变</div>
              <div>速</div>
              <div>器</div>
            </div>
            <div class="mainViewMianText">{{ lista.r_gearbox_model }}</div>
          </div>
        </div>
        <div class="mainViewMian" style="border: none">
          <div class="mainViewMianLi" style="flex: 1">
            <div class="mainViewMianTitle">
              <div>发</div>
              <div>动</div>
              <div>机</div>
              <div>型</div>
              <div>号</div>
            </div>
            <div class="mainViewMianText">{{ lista.r_engine_model }}</div>
          </div>
        </div>
      </div>
      <!-- 价钱 -->
      <div class="moneyView">
        <div class="moneyViewSon">
          <div class="moneyViewSonTitle">厂商指导价</div>
          <div v-if="lista.manufacturer_guide_price" class="moneyViewSonMoneyText">
            {{ lista.manufacturer_guide_price
            }}
            <span style="font-size: 18px">万元</span>
          </div>
          <div
            v-if="!lista.manufacturer_guide_price"
            class="moneyViewSonMoneyText moneyViewSonMoneyTextNo"
          >暂无价格</div>
          <div class="moneyViewSonMoney">
            <img style="z-index: 1" src="../../assets/bg.png" alt />
          </div>
        </div>
        <div class="moneyViewSon">
          <div class="moneyViewSonTitle">近期成交价</div>
          <div v-if="lista.recent_price" class="moneyViewSonMoneyText">
            {{ lista.recent_price }}
            <span style="font-size: 18px">万元</span>
          </div>
          <div v-if="!lista.recent_price" class="moneyViewSonMoneyText moneyViewSonMoneyTextNo">暂无价格</div>
          <div class="moneyViewSonMoney">
            <img style="z-index: 1" src="../../assets/bg.png" alt />
          </div>

          <!-- <div v-if="lista.recent_price != ''" class="moneyViewSonMoney">
            {{ lista.recent_price }}<span style="font-size: 18px">万元</span>
          </div>
          <div
            v-if="lista.recent_price == ''"
            class="moneyViewSonMoney moneyViewSonMoneyNo"
          >
            暂无价格
          </div>-->
        </div>
      </div>
    </div>
    <!-- 图表数据 -->
    <div class="echarts-box" v-if="status && statusLevel">
      <div class="echarts-title">
        <div style="font-size:18px;font-weight: bold;">{{lista.echartsTitle}}风险预测</div>
        <div>全国</div>
      </div>
      <!-- <el-card shadow="hover"> -->
      <div class="echarts">
        <div v-if="status">
          <div style="margin-bottom:10px;font-weight: bold;">保值率分析</div>
          <div id="mains" style="width: 458px; min-height: 320px"></div>
          <div class="tips" style="opacity: 0;">
            <div>---</div>
            <div>⻛险指数⾼于荣枯线则⻛险低，反之则⻛险⾼。</div>
          </div>
        </div>
        <div v-if="statusLevel">
          <div style="margin-bottom:10px;font-weight: bold;">市场风险评价</div>
          <div id="main" style="width: 458px; min-height: 320px"></div>
          <div class="tips">
            <div>---</div>
            <div>荣枯线：⻛险指数⾼于荣枯线则⻛险低，反之则⻛险⾼。</div>
          </div>
        </div>
      </div>
      <!-- </el-card> -->
    </div>
    <!-- 图表数据 -->
    <div class="informationView">
      <p class="informationViewTitle">参数配置</p>
      <p class="basicsTitle">基础信息</p>
      <div class="informationViewFlex">
        <div class="flexView">
          <div class="flexViewLeft">车辆型号</div>
          <div class="flexViewRight">{{ lista.r_product_number }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">功能类型</div>
          <div class="flexViewRight">{{ lista.r_index_data_vc }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">生产厂家</div>
          <div class="flexViewRight">{{ lista.r_manufacturer }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">车辆品牌</div>
          <div class="flexViewRight">{{ lista.r_brand }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">发动机厂</div>
          <div class="flexViewRight">{{ lista.engine_enterprise }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">发动机型号</div>
          <div class="flexViewRight">{{ lista.r_engine_model }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">排量(ml)</div>
          <div class="flexViewRight">{{ lista.displacement }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">功率(kW)</div>
          <div class="flexViewRight">{{ lista.max_output_power }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">最大马力</div>
          <div class="flexViewRight">{{ lista.horsepower }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">最高车速(km/h)</div>
          <div class="flexViewRight">{{ lista.r_max_speed }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">底盘厂商</div>
          <div class="flexViewRight">{{ lista.r_chassis_com }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">底盘型号</div>
          <div class="flexViewRight">{{ lista.r_chassis_model }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">长宽高(mm)</div>
          <div class="flexViewRight" v-if="lista.outer_length">
            {{ lista.outer_length }}× {{ lista.outer_width }}×
            {{ lista.outer_height }}
          </div>
          <div class="flexViewRight" v-if="!lista.outer_length"></div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">货箱尺寸(mm)</div>
          <div class="flexViewRight" v-if="lista.cargo_length">
            {{ lista.cargo_length }}×{{ lista.cargo_weight }}×{{
            lista.cargo_height
            }}
          </div>
          <div class="flexViewRight" v-if="!lista.cargo_length"></div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">轴距(mm)</div>
          <div class="flexViewRight">{{ lista.r_axes_distance }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">轴数(个)</div>
          <div class="flexViewRight">{{ lista.axes_number }}</div>
        </div>

        <div class="flexView">
          <div class="flexViewLeft">轮胎规格</div>
          <div class="flexViewRight">{{ lista.tire_specification }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">轮胎数量(个)</div>
          <div class="flexViewRight">{{ lista.tire_number }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">总质量(kg)</div>
          <div class="flexViewRight">{{ lista.r_total_mass }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">整备质量(kg)</div>
          <div class="flexViewRight">{{ lista.r_curb_quality }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">燃料类型</div>
          <div class="flexViewRight">{{ lista.a_r_fuel_type }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">排放标准</div>
          <div class="flexViewRight">{{ lista.r_emission_standard }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">驱动形式</div>
          <div class="flexViewRight">{{ lista.r_drive_type }}</div>
        </div>
        <div class="flexView">
          <div class="flexViewLeft">弹簧片数(个)</div>
          <div class="flexViewRight">{{ lista.r_number_sping }}</div>
        </div>
        <div class="flexView" style="width: 100%">
          <div style="padding-left: 1%" class="flexViewLeft">综合燃料 消耗(L/100km)</div>
          <div style="padding-left: 1.5%" class="flexViewRight">{{ lista.cfc }}</div>
        </div>
      </div>
      <!-- 上装部分 -->
      <p v-if="jacketList" class="basicsTitle">其他参数</p>
      <div class="informationViewFlex">
        <div class="flexView" v-for="(item, index) in jacketList" :key="index">
          <div class="flexViewLeft">{{ item.key }}</div>
          <div class="flexViewRight">{{ item.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      lista: [], //数据
      // 上装数组
      jacketList: "",
      status: true,
      statusLevel: true,
    };
  },
  computed: {
    ...mapState(["carMsg"]),
  },
  methods: {
    // 获取页面数据
    async getList() {
      let list = {
        vehicleId: this.carMsg.vehicleId,
        vehicleModel: this.carMsg.vehicleModel,
      };
      const { data: res } = await this.$http({
        method: "POST",
        url: "/vehicle/getVehicleDetail",
        data: list,
      });
      if (!res.success) return this.$message.error("数据获取失败");
      this.lista = res.data.result.data.basisData;
    },
    // 图表数据
    async getEcharts() {
      let list = {
        token: this.carMsg.vehicleId,
        vehicleModel: this.carMsg.vehicleModel,
      };
      const { data: res } = await axios({
        method: "post",
        url: "https://apidgu.chinaucv.com/clientPlatform/calculate/getHedgingRate",
        data: list,
        headers: {
          Token: window.sessionStorage.getItem("token"),
        },
      });
      if (!res.success) {
        this.status = false;
        this.$message("该车辆暂无图表信息");
        return;
      }
      let obj = res.data.result.content;
      let nowIndex = "";
      let oldIndex = "";
      obj.forEach((item) => {
        if (item.type == 1) {
          oldIndex = item;
        }
        if (item.type == 2) {
          nowIndex = item;
        }
      });
      var myChart = echarts.init(document.getElementById("mains"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          // formatter: "{b0}<br />{a0}:{c0}<br />{a1}:{c1}%",
          formatter: function (params) {
            let title = "";
            let lineValue = "";
            let str = "";
            if (params.length == 1) {
              // console.log(params, "-------");
              if (params[0].componentIndex == 1) {
                str = `${params[0].axisValue}<br />${params[0].seriesName}:${params[0].value}%`;
              } else {
                str = `${params[0].axisValue}<br />${params[0].seriesName}:${params[0].value}<br />`;
              }
            } else {
              params.forEach((item) => {
                if (item.componentIndex == 0) {
                  title = `${item.axisValue}<br />${item.seriesName}:${item.value}<br />`;
                }
                if (item.componentIndex == 1) {
                  lineValue = `${item.seriesName}:${item.value}%`;
                }
                str = title + lineValue;
              });
            }
            return str;
          },
          trigger: "axis",
          axisPointer: {
            // type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "5%",
          right: "4%",
          bottom: "0",
          containLabel: true,
        },
        legend: {
          data: [nowIndex.name, oldIndex.name],
          // data: ["残值", "保值率"],
        },
        xAxis: [
          {
            type: "category",
            data: ["0年", "1年", "2年", "3年", "4年", "5年", "6年"],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: "7",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位 :  万元",
            min: 0,
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                fontSize: "8",
              },
            },
            axisLine: {
              show: false,
            },
          },
          {
            type: "value",
            min: 0,
            interval: 20,
            axisLabel: {
              textStyle: {
                fontSize: "8",
              },
              formatter: "{value} %",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            // name: "残值",
            name: nowIndex.name,
            type: "bar",
            data: nowIndex.data,
            // data: [90, 80, 60, 50, 40, 30, 20],
            itemStyle: {
              normal: {
                color: "#0088dd", //柱状颜色
              },
            },
            barWidth: 15, // 柱图宽度
            barMaxWidth: 20, // 最大宽度
            // label: {
            //     show: true,
            //     position: 'top'
            // },
          },
          {
            name: oldIndex.name,
            // name: "保值率",
            type: "line",
            yAxisIndex: 1,
            data: oldIndex.data,
            // data: [90, 80, 60, 50, 40, 30, 20],
            itemStyle: {
              normal: {
                color: "Orange", //柱状颜色
              },
            },
            label: {
              show: true,
              position: "bottom",
              textStyle: {
                fontSize: "10",
              },
              formatter: function formatter(p) {
                return "".concat(p.value, "%");
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    async getEchartsLevel() {
      let list = {
        token: this.carMsg.vehicleId,
        vehicleModel: this.carMsg.vehicleModel,
      };
      const { data: res } = await axios({
        method: "post",
        url: "https://apidgu.chinaucv.com/clientPlatform/calculate/getMarketRisk",
        data: list,
        headers: {
          Token: window.sessionStorage.getItem("token"),
        },
      });
      if (!res.success) {
        this.statusLevel = false;
        return this.$message("该车辆暂无图表信息");
      }
      // console.log(res, "--------");
      let obj = res.data.result.content;
      let nowIndex = "";
      let oldIndex = "";
      let danger = "";
      obj.forEach((item) => {
        if (item.type == 3) {
          nowIndex = item;
        }
        if (item.type == 4) {
          oldIndex = item;
        }
        if (item.type == 5) {
          danger = item;
        }
      });
      var myChart = echarts.init(document.getElementById("main"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          formatter: function (params) {
            let title = "";
            let beforeValue = "";
            let lineValue = "";
            let str = "";
            if (params.length == 1) {
              // console.log(params, "-------");
              if (params[0].componentIndex == 2) {
                str = `${params[0].axisValue}<br />${params[0].seriesName}:${params[0].value}%`;
              } else {
                str = `${params[0].axisValue}<br />${params[0].seriesName}:${params[0].value}<br />`;
              }
            } else {
              params.forEach((item) => {
                if (item.componentIndex == 0) {
                  title = `${item.axisValue}<br />${item.seriesName}:${item.value}<br />`;
                }
                if (item.componentIndex == 1) {
                  beforeValue = `${item.seriesName}:${item.value}<br />`;
                }
                if (item.componentIndex == 2) {
                  lineValue = `${item.seriesName}:${item.value}%`;
                }
                str = title + beforeValue + lineValue;
              });
            }
            return str;
          },
          trigger: "axis",
          axisPointer: {
            // type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          // data: ["2021年销量", "2020年销量", "风险指数"],
          data: [nowIndex.name, oldIndex.name, danger.name],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "0",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: "7",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位 : 辆",
            min: 0,
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                fontSize: "8",
              },
            },
            axisLine: {
              show: false,
            },
          },
          {
            type: "value",
            min: 0,
            max: function ma() {
              let arr = danger.data;
              let num = Math.max(...arr);
              if (num < 100) {
                return 100;
              } else {
                return num;
              }
            },
            // interval: 50,
            axisLabel: {
              textStyle: {
                fontSize: "8",
              },
              formatter: "{value} %",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: nowIndex.name,
            // name: "2021年销量",
            type: "bar",
            data: nowIndex.data,
            // data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 50, 30],
            itemStyle: {
              normal: {
                color: "#0088dd", //柱状颜色
              },
            },
          },
          {
            name: oldIndex.name,
            // name: "2020年销量",
            type: "bar",
            data: oldIndex.data,
            // data: [100, 90, 80, 70, 60, 50, 40, 120, 20, 10, 50, 30],
            itemStyle: {
              normal: {
                color: "#93E083", //柱状颜色
              },
            },
          },
          {
            name: danger.name,
            // name: "风险指数",
            type: "line",
            yAxisIndex: 1,
            data: danger.data,
            // data: [100, 90, 80, 70, 160, 50, 40, 30, 20, 10, 50, 30],
            itemStyle: {
              normal: {
                color: "Orange", //柱状颜色
              },
            },
            markLine: {
              symbol: "none",
              data: [
                {
                  type: "average",
                  name: "",
                  yAxis: 100,
                  lineStyle: {
                    normal: {
                      color: "#D5B656", // 这儿设置安全基线颜色
                    },
                  },
                  label: {
                    //自定义显示标签
                    normal: {
                      color: "#CDB66D",
                      formatter: function (params) {
                        return "";
                      },
                      fontSize: "10",
                    },
                  },
                },
              ],
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    // 获取上装
    async getUpMsg() {
      let list = {
        // vehicleModel: 'SX4250MC4Q3',
        vehicleModel: this.carMsg.vehicleModel,
      };
      const { data: res } = await axios({
        method: "POST",
        url: "https://apidgu.chinaucv.com/clientPlatform/thirdCall/getVehicleOtherInfo",
        data: list,
        headers: {
          Token: window.sessionStorage.getItem("token"),
        },
      });
      if (!res.content) {
        return;
      }
      this.jacketList = res.content;
    },
  },
  mounted() {
    this.getList();
    this.getUpMsg();
    this.getEcharts();
    this.getEchartsLevel();
    sessionStorage.setItem('pageKey',1)
  },
};
</script>

<style scoped lang="less">
* {
  margin: 0px;
  padding: 0px;
}
.headerView {
  width: 1000px;
  height: 288px;
  display: flex;
  margin: 0 auto 10px;
  justify-content: space-between;
  .swiperView {
    width: 384px;
    height: 100%;
  }
  .mainView {
    width: 396px;
    height: 100%;
    box-sizing: border-box;
    padding: 20px 16px;
    background: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/user_manage_bottom_20210708.png")
      center center no-repeat;
    background-size: 106%;
    .mainViewTitle {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 30px;
      color: #333333;
      margin-bottom: 30px;
    }
    .mainViewMian {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      .mainViewMianLi {
        flex: 0.5;
        display: flex;
        align-items: center;
        .mainViewMianTitle {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #999999;
          width: 70px;
          display: flex;
          justify-content: space-between;
        }
        .mainViewMianText {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #333333;
          margin-left: 10px;
        }
      }
    }
  }
  .moneyView {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .moneyViewSon {
      width: 200px;
      height: 140px;
      background: #ffffff;
      box-shadow: 0px 3px 6px #e5f3ff;
      opacity: 1;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 20px;
      position: relative;
      .moneyViewSonTitle {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 30px;
        color: #333333;
      }
      .moneyViewSonMoneyText {
        width: 160px;
        position: absolute;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 30px;
        color: #ff0000;
      }
      .moneyViewSonMoney {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 130px;
        height: 94px;
        z-index: 1;
      }
      .moneyViewSonMoneyTextNo {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
.informationView {
  width: 1000px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
  background: #ffffff;
  .informationViewTitle {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-bottom: 14px;
  }
  .basicsTitle {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: #333333;
    background: #ecf3fd;
    height: 32px;
    line-height: 32px;
    box-sizing: border-box;
    padding-left: 10px;
    border: 1px solid #cccccc;
  }
  .informationViewFlex {
    display: flex;
    flex-wrap: wrap;
    .flexView {
      width: 50%;
      display: flex;
      .flexViewLeft {
        display: flex;
        align-items: center;
        width: 200px;
        box-sizing: border-box;
        padding: 5px 0 5px 2%;
        border: 1px solid #cccccc;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
      }
      .flexViewRight {
        display: flex;
        align-items: center;
        flex: 1;
        // text-align: center;
        // padding: 5px 0;
        box-sizing: border-box;
        padding: 5px 0 5px 3%;
        border: 1px solid #cccccc;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 22px;
        color: #333333;
        // max-width: 276px;
        word-break: break-all;
        align-items: center;
      }
    }
  }
}
/deep/.el-carousel__button {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.echarts-box {
  width: 1000px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 3px 6px #e5f3ff;
  border-radius: 10px;
}
.echarts-title {
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom: 1px solid #f0f0f0;
}
.echarts {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 20px;
}
.tips {
  margin-top: 10px;
  display: flex;
  box-sizing: border-box;
  padding-left: 20px;
  align-items: center;
}
.tips > div:nth-of-type(1) {
  margin-right: 10px;
  color: #d0ab3e;
}
.tips > div:nth-of-type(2) {
  color: #666666;
  font-size: 12px;
}
</style>
